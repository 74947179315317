.Header {
  width: 100%;
  font-size: 13px;
  background-image: url(../../../Assets/images/pexels-jack-baghel-2199968-20408440.jpg);
  background-size: cover;
  background-position: center;
  transition: opacity 0.5s ease; /* Transition ekleyin */
}

.Header .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: rgba(37, 37, 37, 0.596);
  z-index: 2;
}
.Header ul {
  position: relative;
  padding: 20px;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  z-index: 99;
}
.Header ul li {
  display: none;
  margin: auto;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 500;
}
.Header .logo {
  position: relative;
  height: 50%;
  width: 50%;
  margin: auto;
  text-align: center;
  z-index: 98;
}
.Header .soonText {
  position: relative;
  color: rgb(255, 255, 255);
  text-align: center;
  z-index: 99;
}
.Header .soonText h1 {
  padding: 20px;
  width: 30%;
  margin: auto;
  border-bottom: 1px solid #fff;
}
.Header .soonText p {
  padding: 20px;
  font-size: 15px;
  font-weight: 600;
}

.icons {
  margin-right: 6px;
}

@media screen and (max-width: 767px) {
  .Header {
    margin: 0;
    border-radius: 0;
    width: 100%;
    height: 20vh;
    font-size: 13px;
    background-size: cover;
  }
  .Header .overlay {
    background-color: rgba(39, 39, 39, 0.767);
  }
  .Header .logo {
    width: 60%;
    height: 60%;
    margin-top: 40px !important;
  }
  .Header .logo img {
    width: 50%;
    height: auto;
  }
  .Header .soonText {
    position: relative;
    color: rgb(255, 255, 255);
    text-align: center;
    z-index: 99;
  }
  .Header .soonText h1 {
    padding: 5px;
    width: 50%;
    margin: auto;
    border-bottom: 1px solid #fff;
  }
  .Header .soonText p {
    padding: 5px;
  }

  .Header ul li {
    color: rgb(255, 255, 255);
    font-size: 8px;
    font-weight: 600;
  }
}
