@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");

.gallery-section {
  margin-bottom: 7.5rem;
}
.gallery-section h1 {
  text-align: center;
  color: #eb2629;
  letter-spacing: 0.005em;
}
.gallery-section .section-title {
  text-align: center;
  color: #858585;
  margin-top: 20px;
  margin-bottom: 20px;
}
.gallery-section .slick-slider {
  margin-top: 50px;
}
.gallery-section img {
  height: 400px !important;
  object-fit: cover;
}
@media only screen and (max-width: 640px) {
  .gallery-section .slick-slider {
    width: 88%;
    margin: auto;
  }
}
