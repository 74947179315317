/* Footer Styles */
.Menu_Footer {
  background-image: url("../../../Assets/images/vecteezy_abstract-red-fluid-wave-background_.jpg");
  background-size: cover;
  object-fit: cover;
  height: 300px;
  z-index: 98;
  margin: 50px;
  margin-bottom: 30px;
  border-radius: 20px;
  margin-top: 223px;
}

.Menu_SosialMidiaIcons {
  height: 100px;
  padding-top: 130px;
  display: flex;
  justify-content: center;
}

.Menu_SosialMidiaIcons a {
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  border-radius: 50%;
}

.Menu_SosialMidiaIcons a i {
  font-size: 2em;
  opacity: 0.9;
  color: white;
}

.Menu_FooterBottom {
  padding-top: 80px !important;
  color: white;
  text-align: center;
  padding: 10px;
}
.Menu_FooterBottom a {
  text-decoration: none;
  color: rgb(197, 202, 207);
}
@media screen and (max-width: 640px) {
  .Menu_Footer {
    height: 100px;
    margin: 10px;
    border-radius: 10px;
    background-position: bottom;
    background-size: auto;
    margin-top: 22%;
  }
  .Menu_Footer .Menu_SosialMidiaIcons,
  .Menu_Footer .Menu_FooterBottom {
    padding-top: 5% !important;
  }
  .Menu_SosialMidiaIcons {
    height: 60px;
  }
  .Menu_SosialMidiaIcons a i {
    font-size: 1em;
  }
  .Menu_FooterBottom p {
    font-size: 10px;
  }
}
