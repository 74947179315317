@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Sacramento&display=swap");

.mheader {
  width: 100%;
  height: 73px;
  line-height: 69px;
  z-index: 999;
  font-size: 12px;
  font-weight: 600;
  position: fixed;
  top: 0;
  background-color: #fff;
}
.mheader-navigation {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Roboto", sans-serif;
}
/* .menu_right,
.cart__icon {
  display: none !important;
} */
.mheader-logo img {
  width: 105px;
  object-fit: contain;
}
.mheader-nav__list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
  column-gap: 3rem;
}
/* .menu__right {
  display: none;
} */

.mheader-nav__item a {
  color: #5a5959;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  font-weight: 600;
}

.mheader-nav__item a:hover {
  color: #f50a0a;
}
/* .language span {
  padding: 1px 10px;
  cursor: pointer;
}
.language span:nth-child(1) {
  border-right: 2px solid #000;
} */

.mheader-mobile__menu span i {
  font-size: 1.3rem;
  color: #eb1010;
}
.mheader-mobile__menu {
  display: none;
}

/* ==========  make it responsive ========= */

@media only screen and (max-width: 992px) {
  .mheader {
    padding: 10px;
  }

  .mheader-navigation {
    justify-content: space-between;
  }
  .mheader-navigation .language {
    display: none;
  }
  /* .menu_right,
  .cart__icon {
    display: none !important;
  } */
  .mheader-logo img {
    width: 100px;
    height: 70px;
    object-fit: contain;
  }
  .mheader-nav__menu {
    position: fixed;
    top: 12%;
    width: 90%;
    margin: auto;
    height: 55%;
    z-index: 99;
    background: #bbb9ca;
    display: none;
    border-radius: 15px;
    transition: all 0.5s ease-in-out;
  }
  .mheader-nav__list__wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #ffffff;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 5px 5px 15px -5px #f50a0aaf;
    justify-content: center !important;
    padding: 0px 20px;
  }
  .mheader-nav__list {
    flex-direction: column;
    padding: 0;
  }
  .mheader-nav__item a {
    font-size: 0.9rem;
    display: block;
    height: 70px;
  }

  /* .custom__search {
    border: 1px solid #c4c4c425;
    padding: 5px 15px;
    font-size: 0.9rem;
    height: 30px;
    margin-top: 15px;
  } */
  .mheader-mobile__menu {
    display: block;
  }
  .mheader-logo h2 {
    font-size: 1.5rem;
  }
  .mheader-active__menu {
    display: block;
    transition: all 0.5s ease-in-out;
  }
}

@media screen and (max-width: 640px) {
  .mheader {
    height: 80px !important;
  }
  .mheader-navigation .mheader-logo img,
  .mheader-navigation .mheader-logo .mheader-nav__menu {
    margin-top: -20px !important;
  }
}
