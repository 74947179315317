.App {
  text-align: center;
  padding: 2rem !important ;
}

.App-logo {
  pointer-events: none;
  object-fit: cover;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h2 {
  color: white;
}
#our-partnerss {
  /* background-color: #f8f6eb; */
  padding: 2rem !important ;
}
.imagee {
  width: 75% !important;
  height: 75% !important;
}
.mainContainer {
  object-fit: cover;
  background-color: #cfcfcf26;
}

.mainContainer .container {
  padding: 4px 0;
}

.mainContainer .container img {
  object-fit: contain !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-items: center;
}
@media (max-width: 575px) {
  #our-partnerss {
    padding: 0 !important ;
  }
  #our-partnerss button {
    display: none !important;
  }
  .imagee {
    width: 100% !important;
    height: 100% !important;
    margin-left: 101px !important;
    margin-right: 101px !important;
  }
  .mainContainer .container img {
    object-fit: cover !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    height: auto;
    object-fit: contain;
    width: 30%;
    max-width: 100% !important;
  }
}
.HelloMerhaba {
  height: 80px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

div h3 {
  color: white;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
