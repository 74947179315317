.Menu_sec1 {
  position: relative;
  height: 400px;
}
.Menu_sec1 .Header {
  position: absolute;
  z-index: 999;
  bottom: 50px;
  top: 0;
}
.Menu_sec1 .Header .logo {
  width: 54%;
  height: 50%;
  padding-top: 23px;
}
.Menu_sec1 .Header .logo img {
  width: 54%;
  height: 79%;
  object-fit: contain;
  padding-top: 3%;
}
.Menu_sec1 .Header .soonText {
  color: rgb(185 185 185);
}
.Menu_sec1 .BrandsSwiper {
  position: absolute;
  bottom: 3px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #f5f5f5;
  padding: 15px;
}
.Menu_sec1 .BrandsSwiper .swiper {
  width: 70%;
}
.Menu_sec1 .BrandsSwiper .Brand_Card {
  width: 60% !important;
  margin: auto !important;
}
.Menu_sec1 .Brand_Card img {
  width: 70px;
  height: 75px;
  object-fit: contain;
}
.swiper-button-next,
.swiper-button-prev {
  top: 55% !important;
  color: #000000 !important;
  background-color: rgb(189 189 189 /0%) !important;
  width: 20px !important;
  height: 20px !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 3px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 3px !important;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 10px !important;
}
/* Brand Desc */
.Brand_Desc {
  background-color: #f5f5f5;
  margin: auto;
  margin-top: 50px;
  width: 70%;
  padding: 35px;
  text-align: center;
  border-radius: 15px;
}
.Brand_Desc h6 {
  font-family: "Pacifico", cursive;
}
.Brand_Desc p {
  font-size: 14px;
  font-weight: 600;
  padding: 20px;
}
.Brand_Cat {
  padding: 10px;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}
.Brand_Cat .swiper-slide {
  padding: 0 5px !important;
}
.Brand_Cat .swiper-wrapper {
  padding-left: 0 !important;
}
.Brand_Cat .cat_Card {
  border: 1px solid #ffd4d4;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 3%;
  cursor: pointer;
  position: relative;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.cat_Card:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px rgb(252, 160, 160);
}

.cat_Card:active:after {
  box-shadow: 0 0 0 0 rgb(245, 189, 189);
  position: absolute;
  border-radius: 4em;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.cat_Card:active {
  top: 1px;
}
.Brand_Cat .cat_Card span {
  width: 60px;
  height: 60px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 50%;
  padding: 5%;
}

.Brand_Cat .cat_Card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Brand_Cat p {
  width: 60%;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0 !important;
}
.Brand_Menu {
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5%;
}
.Brand_Menu .Recipe_Card {
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 120px;
  width: 40%;
  padding: 22px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
  border-radius: 15px;
}
.Brand_Menu .Recipe_Card .Recipe_img {
  position: absolute;
  left: -28px;
  width: 140px;
  top: -13px;
  height: 140px;
}
.Brand_Menu .Recipe_Card .Recipe_info {
  width: 60%;
  padding: 5px;
}
.Brand_Menu .Recipe_Card .Recipe_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
  border-radius: 50%;
}
.Brand_Menu .Recipe_Card .Recipe_info h6 {
  font-size: 16px;
}

.Brand_Menu .Recipe_Card .Recipe_info p {
  color: #a3a3a3;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
}
.Brand_Menu .Recipe_Card .price {
  position: absolute;
  right: 10px;
  top: 33%;
}
.Brand_Menu .Recipe_Card span {
  color: #000000;
  font-size: 31px;
  font-weight: 600;
  font-family: "Comfortaa", sans-serif;
}
.Brand_Menu .Recipe_Card span #currency {
  color: #ff0000;
  font-family: "REM", sans-serif;
}
#star {
  color: #ffa200;
  font-size: 20px;
}
/* Recipe_Details_Card */
.Recipe_container {
  height: 900px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}
.Recipe_Details_Card {
  padding: 4%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.Recipe_Details_Card .Recipe_info {
  margin-top: 100px;
  width: 50%;
}
.Recipe_Details_Card .Recipe_info .details {
  margin-top: 10px;
}
.Recipe_Details_Card .Recipe_info .details p {
  width: 80%;
  margin: 19px 0;
}
.Recipe_Details_Card .Recipe_info .details ul {
  padding: 0;
}
.Recipe_Details_Card .Recipe_info .details ul li {
  display: inline-block;
  background-color: #ffcccc;
  padding: 7px;
  border-radius: 5px;
  margin: 5px;
}
.Recipe_Details_Card .Recipe_info .cal_pri {
  margin-top: 50px;
}
.Recipe_Details_Card .Recipe_info .cal_pri .kal img {
  width: 100px;
  margin-bottom: 20px;
}
#MoneyLogo {
  width: 86px;
}
.Recipe_Details_Card .Recipe_info .cal_pri .kal span {
  font-size: 30px;
}
#price {
  margin-left: 17px;
}
.Recipe_Details_Card .Recipe_images {
  width: 50%;
}
.Recipe_Details_Card .Recipe_images .swiper {
  height: 100%;
  border-radius: 20px;
}
.Recipe_Details_Card .Recipe_images .swiper .swiper-wrapper .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.Go_back {
  position: absolute;
  top: 50px;
  left: 35px;
  color: #bbbbbb;
  z-index: 9999;
}
.Recipe_card_Modal .modal-body {
  padding: 0 !important;
}
.Recipe_card_Modal .modal-content {
  height: 75%;
}
.Recipe_card_Modal .modal-dialog {
  max-width: 59% !important;
}
/* Keyframes for fade up animation */
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Applying the fade-up animation */
.fade-up {
  animation: fadeUp 1s ease-in-out;
}

@media screen and (max-width: 767px) {
  /* Menu Section one Header and Brand Slider */
  .Menu_sec1 {
    position: relative;
    height: 30vh;
  }
  .Menu_sec1 .Header {
    position: absolute;
    z-index: 999;
  }
  .Menu_sec1 .Header .logo {
    width: 54%;
    height: 50%;
    padding-top: 23px;
  }
  .Menu_sec1 .Header .soonText {
    color: rgb(185 185 185);
  }
  .Menu_sec1 .BrandsSwiper {
    position: absolute;
    bottom: 3px;
    width: 100%;
    right: 0;
    padding: 0;
    height: 90px;
    z-index: 1000;
    background-color: #ededed;
    margin: 0;
    border-radius: 0;
  }
  .Menu_sec1 .BrandsSwiper .swiper {
    width: 100%;
  }
  .Menu_sec1 .BrandsSwiper .Brand_Card {
    width: 60% !important;
    margin: auto !important;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Menu_sec1 .Brand_Card img {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: 55% !important;
    color: #000000 !important;
    background-color: rgb(189 189 189 / 0%) !important;
    padding: 20px;
    width: 40px !important;
    height: 40px !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: -10px !important;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: -10px !important;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 16px !important;
    font-weight: 700;
  }
  /* Brand Desc */
  .Brand_Desc {
    background-color: #f5f5f500;
    margin: 0px;
    padding-top: 0px;
    padding: 0;
    text-align: start;
    border-radius: 15px;
    width: 100%;
  }
  .Brand_Desc h6 {
    font-family: "Pacifico", cursive;
  }
  .Brand_Desc p {
    font-size: 12px;
    font-weight: 600;
    padding: 4px;
  }
  .Brand_Cat {
    margin: 5px;
    padding: 0px;
  }

  .Brand_Cat .cat_Card span {
    width: 35px;
    height: 35px;
  }

  .Brand_Cat p {
    font-size: 10px;
  }
  .Brand_Menu {
    margin: auto;
    display: block;
    width: 100%;
  }
  .Brand_Menu .Recipe_Card {
    margin: auto;
    position: relative;
    height: 90px;
    width: 96%;
    padding: 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 25px;
    margin-left: 4%;
    border-radius: 15px;
  }
  .Brand_Menu .Recipe_Card .Recipe_img {
    position: absolute;
    left: -20px;
    width: 100px;
    top: -6px;
    height: 100px;
  }
  .Brand_Menu .Recipe_Card .Recipe_info {
    width: 75%;
    padding: 10px;
    padding-left: 10%;
  }
  .Brand_Menu .Recipe_Card .Recipe_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
  }
  .Brand_Menu .Recipe_Card .Recipe_info h6 {
    font-size: 15px;
  }

  .Brand_Menu .Recipe_Card .Recipe_info p {
    color: #a3a3a3;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .Brand_Menu .Recipe_Card .price {
    position: absolute;
    right: 10px;
    top: 33%;
  }
  .Brand_Menu .Recipe_Card span {
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    font-family: "Comfortaa", sans-serif;
  }
  .Brand_Menu .Recipe_Card span #currency {
    color: #ff0000;
    font-family: "REM", sans-serif;
  }
  #star {
    color: #ffa200;
    font-size: 20px;
  }
  .Recipe_container {
    height: 95vh;
  }
  .Recipe_card_Modal .modal-dialog {
    max-width: 100% !important;
  }

  .Recipe_Details_Card {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
  }
  .Recipe_Details_Card .Recipe_info .details p {
    font-size: 16px;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri {
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri .kal {
    width: 40%;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri .kal img {
    width: 50px;
  }
  #MoneyLogo {
    width: 40px;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri .kal span {
    font-size: 21px;
  }
  .Recipe_Details_Card .Recipe_info .details ul li {
    margin: 2px;
    font-size: 12px;
  }
  .Recipe_Details_Card .Recipe_images {
    width: 100%;
    height: 46%;
  }
  .Recipe_Details_Card .Recipe_info {
    margin-top: 30px;
    width: 100%;
  }
  .Go_back {
    top: 29px;
    left: 30px;
    color: #535353;
    font-size: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .Recipe_container {
    height: 400px;
  }
  .Recipe_Details_Card h1 {
    font-size: 20px;
  }
  .Recipe_Details_Card Recipe_images {
    width: 50%;
  }
  .Recipe_Details_Card .Recipe_info .details {
    margin-top: 10px;
  }
  .Recipe_Details_Card .Recipe_info .details h2 {
    font-size: 25px;
  }
  .Recipe_Details_Card .Recipe_info .details p {
    font-size: 10px;
    width: 90%;
  }
  .Recipe_Details_Card .Recipe_info {
    margin-top: 20px;
  }
  .Go_back {
    top: 6px;
    left: 13px;

    width: 20px !important;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri {
    margin-top: 30px;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri .kal img {
    width: 40px;
    margin-bottom: 0px;
  }
  #MoneyLogo {
    width: 40px;
  }
  .Recipe_Details_Card .Recipe_info .cal_pri .kal span {
    font-size: 15px;
  }
  .Recipe_Details_Card .Recipe_info .details ul li {
    margin: 2px;
    font-size: 10px;
  }
  .Brand_Menu .Recipe_Card .Recipe_img {
    left: -47px;
  }
}
