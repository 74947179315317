@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root {
  --color-text: #616161;
  --color-text-btn: #ffffff;
  --card1-gradient-color1: #f12711;
  --card1-gradient-color2: #f5af19;
  --card2-gradient-color1: #7f00ff;
  --card2-gradient-color2: #e100ff;
  --card3-gradient-color1: #3f2b96;
  --card3-gradient-color2: #a8c0ff;
  --card4-gradient-color1: #11998e;
  --card4-gradient-color2: #38ef7d;
}

.card-wrap {
  width: 400px;
  background: #fff;
  border-radius: 20px;
  border: 5px solid #fff;
  overflow: hidden;
  color: var(--color-text);
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transition: all 0.2s ease-in-out;
}
.card-wrap:hover {
  transform: scale(1.1);
}
.card-header {
  height: 200px;
  width: 100%;
  border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
  display: grid;
  place-items: center;
}
.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
}

.card-header i {
  color: #fff;
  font-size: 72px;
}
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
.card-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.card-text {
  text-align: center;
  font-size: 12px;
  margin-bottom: 20px;
}
.card-btn {
  border: none;
  border-radius: 100px;
  padding: 5px 30px;
  color: #fff;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.card-btn.one {
  background: linear-gradient(
    to left,
    var(--card1-gradient-color1),
    var(--card1-gradient-color2)
  );
}

.branches-container {
  margin-top: 6%;
  text-align: center;
}

.title-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: 10px;
  background-color: #f12711;
}

.logox {
  padding-top: 6% !important;
  position: relative;
  height: 50%;
  width: 50%;
  margin: auto;
  text-align: center;
  z-index: 98;
}

.branch-card-container {
  justify-content: space-evenly;
  margin-left: 5%;
  margin-right: 5%;
}

@media screen and (max-width: 900px) {
  .logox {
    padding-top: 20% !important;
  }
}

@media screen and (max-width: 640px) {
  .logox {
    padding-top: 36% !important;
  }
}
