/* Footer Styles */
.footer {
  position: relative;
  background-image: url("../../Assets/images/vecteezy_abstract-red-fluid-wave-background_.jpg");
  background-size: cover;
  object-fit: cover;
  height: 300px;
  z-index: 98;
  display: flex;
  margin: 50px;
  margin-bottom: 30px;
  border-radius: 20px;
}

#logoAbout {
  order: 1;
}
#logoAbout img {
  padding-top: 3rem;
  padding-left: 3rem;
}
#logoAbout p {
  padding-top: 0.5rem;
  padding-left: 3rem;
}

#socialCopyright {
  order: 2;
}

#contact {
  order: 3;
}
#contact svg {
  width: 20px;
  height: 20px;
}

.SocialMediaIcons {
  height: 100px;
  padding-top: 130px;
  justify-content: center;
}

.SocialMediaIcons a {
  text-decoration: none;
  padding: 10px;
  margin: 10px;
  border-radius: 50%;
}

.SocialMediaIcons a i {
  font-size: 2em;
  opacity: 0.9;
  color: white;
}

#copyright {
  margin-top: 3rem;
  padding-top: 3rem;
}

.FooterBottom {
  padding-top: 80px !important;
  color: white;
  text-align: center;
  padding: 10px;
}

.FooterBottom a {
  text-decoration: none;
  color: rgb(197, 202, 207);
}

p {
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  .footer {
    height: 100%;
    margin: 10px;
    border-radius: 10px;
    background-position: bottom;
    background-size: auto;
    padding: 2.5rem;
  }

  #logoAbout {
    order: 1;
    text-align: center;
  }
  #logoAbout img,
  #logoAbout p {
    padding-top: 0;
    padding-left: 0;
  }
  #logoAbout p {
    margin-top: 0.5rem;
    text-align: start;
  }
  #logoAbout img {
    margin-top: -20px;
  }
  #logoAbout p {
    margin-top: 20px;
  }

  #contact {
    order: 2;
  }
  #contact div {
    justify-content: start;
    display: flex;
  }
  #contact svg {
    width: 20px;
    height: 20px;
  }

  #copyright {
    margin-top: 50px;
    padding-top: 0;
  }

  #socialCopyright {
    order: 3;
    margin-top: 10px;
    padding-top: 10px;
  }

  .footer .SocialMediaIcons,
  .footer .FooterBottom {
    padding-top: 0 !important;
  }

  .SocialMediaIcons {
    height: 60px;
  }

  .SocialMediaIcons a i {
    font-size: 1em;
  }

  .FooterBottom p {
    font-size: 14px;
  }
}
