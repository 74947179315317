@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
}
body {
  position: relative;
  height: 100%;
}
#root {
  height: 100%;
}
.gaTCvF {
  background-color: #000931 !important;
  border-radius: 20px;
  width: 100% !important;
}
.dzu-previewContainer {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin: 5px;
}
.dzu-previewContainer img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.fvWJS a {
  color: rgb(255 255 255 / 58%) !important;
}
a {
  text-decoration: auto !important;
}
button {
  font-size: 12px !important;
}
.franchise-container input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.franchise-container input[type="submit"]:hover {
  background-color: #45a049;
}

.franchise-container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 75% !important;
  min-height: 580px;
}
.franchise-header {
  position: relative;
  width: 100%;
  height: 250px;
  font-size: 13px;
  background-position: center;
  background-image: url("../src/Assets/images/pexels-jack-baghel-2199968-20408440.jpg");
  background-size: cover;
  z-index: 0;
  transition: opacity 0.5s ease;
}
.brands-title {
  padding-top: 6% !important;
}
.franchise-header .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: rgba(37, 37, 37, 0.6);
  z-index: 2;
}
.franchise-header .logo {
  position: relative;
  height: 50%;
  width: 50%;
  margin: auto;
  text-align: center;
  z-index: 98;
}
@media screen and (max-width: 900px) {
  .brands-title {
    padding-top: 18% !important;
  }
}

@media screen and (max-width: 640px) {
  .brandx {
    margin-bottom: 40px;
  }
  .brands-title {
    padding-top: 30% !important;
  }
  .brandx img {
    height: auto;
    border-radius: 10px;
    width: 40%;
    margin-top: 10px;
  }
}

/* containers*/
@media (min-width: 1800px) {
  .D_container {
    max-width: 1440px !important;
    margin-left: 18% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .D_container {
    max-width: 900px !important;
    margin-left: 24% !important;
  }
}
