.AdminNavbar {
  height: 50px;
  background-color: #000931;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 40px;
  color: #fff;
  display: flex;
  align-items: center;
}
.SideNav_container {
  position: fixed;
  top: 0;
  margin: 10px;
  bottom: 0;
  width: 11%;
}
.sideNav {
  height: 100%;
}
.StaticCard {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 10px;
}
.StaticCard h2 {
  font-size: 20px;
}
.StaticCard > div > h2 {
  display: inline-block;
  font-size: 16px;
  margin-left: 20px;
}
