/* Brand Page */
.AddBrandForm {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  left: 70%;
  padding: 3%;
  background-color: #ffffff;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.5s;
}
.AddBrandForm input,
.AddBrandForm textarea,
.form-select,
.lmMaya {
  display: block;
  margin: 10px 0;
  width: 80% !important;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #dadada !important;
  font-size: 15px !important;
}
#IngInput {
  width: 25% !important;
  display: inline-block;
  margin-right: 10px;
  font-size: 12px !important;
}
.Brands_container {
  margin-top: 40px;
}
.label_card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  margin: 10px;
  height: 250px;
  text-align: center;
  border-radius: 20px;
  position: relative;
  width: 15%;
}
.label_card .post-img {
  width: 40%;
  margin: auto;
}

.label_card .post-img img {
  height: 60px;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.label_card p {
  height: 43px !important;
}

.label_card .title {
  margin: 20px 0;
  font-size: 15px !important;
}

.label_card .meta-top ul {
  padding: 0 !important;
}
.label_card .meta-top ul li i {
  margin-left: 10px;
}
.label_card .read-more {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.label_card .read-more button {
  font-size: 13px;
}
.delete_Button {
  position: absolute;
  top: 10px;
  right: 10px;

  cursor: pointer;
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .label_card {
    width: 21%;
  }
}
@media (max-width: 640px) {
  .label_card {
    width: 90%;
  }
  .label_card .post-img {
    width: 20%;
  }
}
